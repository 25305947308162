function ProjectCard(props) {
    function handleClick () {
        console.log('hi')
    }
    return (
        <div className={`project-card ${props.cardType}`}>
            <img onClick={() => handleClick} src={props.img} />
            <div className="project-card-details">
                <div className="project-card-title">
                    <h3>{props.title}</h3>
                </div>
                <div className={`project-card-description ${props.descPosition}`}>
                    <p>{props.text}</p>
                </div>
                <div className={`project-link ${props.online}`}>
                    <a href={props.url}><span className="project-url">My project is on the internet</span></a>
                </div>
                <div className="project-card-techs">
                    <ul>
                        {
                            props.techs.map((el) => (
                                <span>{el}</span>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ProjectCard