import loadingGif from './../img/loadin2.gif'

function Loading () {
    return(
        <div className="loading">
            <img src={loadingGif} />
        </div>
    )
}

export default Loading;