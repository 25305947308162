import logo from "./../img/logo3.png";
import { AiOutlineHeart } from 'react-icons/ai'

function Footer() {
    return(
        <div className="footer ">
                <hr/>
                <p className="cp-text">
                    Made with <AiOutlineHeart/> By <a href='/'>YounousElIdrissi</a> © 2022.
                </p>
                {/* <img src={logo} alt="logo"/> */}
        </div>
    )
}

export default Footer