import { FiSend } from 'react-icons/fi'

function Contact(){
    return(
        <div id="contact" className="contact-section">
            <div className="component-body">
                {/* <h1>Contact me</h1>
                <form>
                    <label for="fname">Full Name</label>
                    <input type="text" id="fname" name="fname" placeholder="Your name..." />
                    <label for="email">Email</label>
                    <input type="text" id="email" name="email" placeholder="Your email..." />
                    <label for="subject">Subject</label>
                    <input type="text" id="subject" name="subject" placeholder="Subject..." />
                    <textarea id="subject" name="subject" placeholder="Write something.." style={{height:"200px"}}></textarea>
                    <input type="submit" value="Send"/>
                </form> */}
                <h2>{'>'} Get In Touch</h2> 
                <h4>Feel free to send me a message, my inbox is always open</h4>
                <h5>I am looking for an end-of-study internship!</h5>
                <a href='mailto:younes.idrissi.07@gmail.com'>
                    <button>
                        <FiSend size={20}/>
                    </button>
                </a>
                
            </div>
            
        </div>
    )
}

export default Contact;