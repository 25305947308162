import logo from "./../img/logo3.png";
import { MdOutlineDarkMode, MdOutlineLightMode } from 'react-icons/md'
import {Link} from 'react-scroll'
import { useState } from 'react'

function NavBar ({switchMode, theme}) {
    
    function displayButton () {
        if(theme==='light') {return (<MdOutlineDarkMode 
            size={25}
            style={{cursor: 'pointer'}}
            onClick={switchMode}
        />)}
        else return (<MdOutlineLightMode 
            size={25}
            style={{cursor: 'pointer'}}
            onClick={switchMode}
        />)
    }


    return(
        <div className="navbar">
            <div className="logo">
                {/* <img src={logo} alt="logo" /> */}
                EL IDRISSI Younous
            </div>
            <div className="dark-mode">
                {displayButton()}
            </div>
            <div className="my-list">
                <ul>
                    <li><Link activeClass="active" to="home" spy={true} smooth={true}><span className="link">Home</span></Link></li>
                    <li><Link activeClass="active" to="about" spy={true} smooth={true}><span className="link">About</span></Link></li>
                    <li><Link activeClass="active" to="projects" spy={true} smooth={true}><span className="link">Projects</span></Link></li>
                    <li><Link activeClass="active" to="contact" spy={true} smooth={true}><span className="link">Contact</span></Link></li>
                </ul>
            </div>
        </div>
    )
}

export default NavBar;