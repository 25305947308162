import { AiOutlineMenu, AiFillHome } from 'react-icons/ai'
import { useState } from 'react'
import {Link} from 'react-scroll'

function SecNavbar () {
    const [visible, setVisible] = useState(false)
    const handleClick = () => {
        visible ? setVisible(false) : setVisible(true)
    }

    return(
        <div className="sec_navbar">
            {/* <div className='menu-buttons'>
                <a href='/home'>
                    <AiFillHome 
                        size={25} 
                        style={{margin: "7px", cursor: "pointer"}}
                    />
                </a>
                <AiOutlineMenu 
                    onClick={handleClick}
                    size={25}
                    style={{margin: "7px", cursor: "pointer"}}
                />
                
            </div>
            <div className='menu' style={{display: visible ? 'inline' : 'none'}}>
                    <ul>
                        <li>
                            <a href='/home'>Home</a>
                        </li>
                        <li>
                            <a href='/about'>About</a>
                        </li>
                        <li>
                            <a href='/projects'>Projects</a>
                        </li>
                    </ul>
            </div> */}
                <a href='/home'>
                    <AiFillHome 
                        size={25} 
                        style={{margin: "7px", cursor: "pointer"}}
                    />
                </a>
            <div className="my-list">
                <ul>
                    <li><Link activeClass="active" to="home" spy={true} smooth={true}><span className="link">Home</span></Link></li>
                    <li><Link activeClass="active" to="about" spy={true} smooth={true}><span className="link">About</span></Link></li>
                    <li><Link activeClass="active" to="projects" spy={true} smooth={true}><span className="link">Projects</span></Link></li>
                    {/* <li>
                        <a href='/home'>Home</a>
                    </li>
                    <li>
                        <a href='/about'>About</a>
                    </li>
                    <li>
                        <a href='/projects'>Projects</a>
                    </li> */}
                </ul>
            </div>
        </div>
    )
}

export default SecNavbar