import { FiLinkedin, FiGithub} from 'react-icons/fi'
import { SiGmail } from 'react-icons/si'

function Links () {
    return (
        <div className="my-links">
            <a className="linkedin" title="Linkedin" href="https://www.linkedin.com/in/younous-el-idrissi-470168211/"><FiLinkedin size={25}/></a>
            <a className="gmail" title="Send email" href="mailto:younes.idrissi.07@gmail.com"><SiGmail size={25} /></a>
            <a className="github" title="Github" href="https://github.com/younousIdrissi"><FiGithub size={25} /></a>
            <hr/>
        </div>
    )
}

export default Links