import React from 'react';
import { FaAngleUp } from 'react-icons/fa';
import Scroll from 'react-scroll'
import {useState} from 'react'
var scroll = Scroll.animateScroll

const ScrollToTop = () => {
    const [visible, setVisible] = useState(false)
  
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 600){
        setVisible(true)
      } 
      else if (scrolled <= 600){
        setVisible(false)
      }
    }; 
    window.addEventListener('scroll', toggleVisible);
    return (
        <div className="top-to-btm">
            <FaAngleUp 
                onClick={scroll.scrollToTop} 
                className="icon-position icon-style" 
                style={{display: visible ? 'inline' : 'none'}}    
            />
        </div>
    );
};
export default ScrollToTop;