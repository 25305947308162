import Scroll from 'react-scroll'
import Footer from "./footer"
import ProjectCard from "./projectCard"
import img1 from './../img/labotaki.gif'
import img2 from './../img/Sport_reservation.PNG'
import img3 from './../img/a4c.PNG'
import img4 from './../img/portfolio2.PNG'
var scroll = Scroll.animateScroll

function Projects(){
    const text = 'A JavaFX call center management system project it is used for receiving or transmitting a large volume of enquiries between the agent and the customer,it handles the communication between agents & clients it can be used for the both side client & agent side to handle the messages and issues.'
    const title = 'Project Screen shot'
    const url = 'https://www.labo-taki.com'
    return(
        <div id="project" className="projects">
            <div id="about">
                <h2> {'>'} My Latest Projects</h2>
                <div className='projects-list'>
                    <ProjectCard 
                        cardType="normal" 
                        descPosition='descRight' 
                        title="Laboratoire Taki d'analyses médicales" 
                        text="This web site is for a medical analysis laboratory, it represents their services and gives informations about their local." 
                        img={img1} 
                        online={'online'}
                        url={url}
                        techs={['HTML', 'Css', 'Javascript', 'ReactJS']}
                    />
                    <ProjectCard 
                        cardType="reverse" 
                        descPosition='descLeft' 
                        title="Sports fields booking site" 
                        text="I built this web site to make it easy for ENSEM students to book a sport field. It has a database for students informations."
                        img={img2} 
                        online={'not-online'}
                        techs={['Javascript', 'ReactJS', 'NodeJS', 'Express', 'MongoDB']}
                    />
                    <ProjectCard 
                        cardType="normal" 
                        descPosition='descRight' 
                        title="act4community By OCP" 
                        text="Act4Community By OCP is an association helping small cooperatives to present their products to client wherever in the world. This web site is where clients can find all the natural products and the informations about Moroccan cooperatives." 
                        img={img3} 
                        online={'not-online'}
                        url={url}
                        techs={['Javascript', 'ReactJS', 'NodeJS', 'Express', 'MongoDB']}
                    />
                    <ProjectCard 
                        cardType="reverse" 
                        descPosition='descLeft' 
                        title="My portfolio" 
                        text="I built this portfolio from scratch to provide my contact info and present my projects to everyone interested on it." 
                        img={img4}
                        online={'online'} 
                        url={'#'}
                        techs={['HTML', 'Css', 'Javascript', 'ReactJS']}
                    />
                    
                </div>
            </div>
        </div>
    )
}

export default Projects;