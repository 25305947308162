import './App.css';
import NavBar from "./components/navbar"
import Home from "./components/home"
import About from "./components/about"
import Projects from "./components/projects"
import Footer from "./components/footer"
import { useState, useEffect } from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import ScrollToTop from './components/ScrollToTop';
import Admin from './components/admin'
import SecNavbar from './components/sec_navbar'
import Contact from './components/contact'
import Loading from './components/loading'
import Links from './components/links'

function App() {
  const [theme, setTheme] = useState('dark')
  const [loader, setLoader] = useState(true)

  function switchMode() {
    if(theme==='light') {setTheme('dark')}
    else {setTheme('light')}
    console.log(theme);
  }

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 4000)
  }, [])
  
  return loader ? (
      <Loading />
  ) : (
    <div className={`App ${theme}`}>
      <Links />
      <NavBar switchMode={switchMode} theme={theme}/>
      <SecNavbar />
      <ScrollToTop />
      <Home />
      <About/>
      <Projects/>
      <Contact />
      <Footer />
      
    </div>
  );
}

export default App;
