import Footer from "./footer"
import about from "./../img/AboutMe.png"

function About(){
    return(
        <div id="about" className="about">
                <div className="about-text">
                <h2>{'>'} About me</h2>
                    <p>Hello! My name is Younous, I'm 22 years old. I'm 
                        a computer science student (future engineer) and 
                        a full stack Javascript developer.
                    </p>
                    <p>My interest in computer science started five years ago when I discovered -for the first time- Python and problem solving, then I started web development and I worked on some small projects.
                    </p>
                    <p>Now, I love programming and I enjoy building web sites and bringing up new ideas. I prefer Javascript as a language for full stack developement with NodeJS, NextJS, reactJS and angularJS.</p>
                    <p>Here are a few technologies I've been working with recently :</p>
                    <div className="skills">
                        <ul>
                            <span>{'>'} Javascript</span>
                            <span>{'>'} ReactJS</span>
                            <span>{'>'} angularJS</span>
                            <span>{'>'} Nodejs</span>
                        </ul>
                        <ul>
                            <span>{'>'} Expressjs</span>
                            <span>{'>'} PHP</span>
                            <span>{'>'} MongoDB</span>
                            <span>{'>'} MySQL</span>
                        </ul>
                    </div>
                </div>
                <div className="about-img">
                     <img src={about} />
                </div>
        </div>
    )
}

export default About;