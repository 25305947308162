import {Link} from 'react-scroll'
import About from "./about"
import Projects from "./projects"
import Footer from "./footer"
import ScrollToTop from './ScrollToTop';
import NavBar from "./navbar"
import MyPic from "./../img/me.jpg"
import { FiLinkedin, FiGithub} from 'react-icons/fi'
import { SiGmail } from 'react-icons/si'
import { RiWhatsappLine } from 'react-icons/ri'
import { IoMdDownload } from 'react-icons/io'
import resume from './../files/resume.pdf'

function Home(){
    return(
        <div className='home'>
            <div id="home" className="">
                <div className='first-page'>
                    <div className='home-frs'>
                        <div className='home-img'>
                            <img src={MyPic} alt="my-photo" />
                        </div>

                        <h3>El Idrissi Younous</h3>
                        <h3><i>“There’s no place like 127.0.0.1” </i><br />- Somebody -</h3>
                    </div>

                    <div className='home-sec'>
                        <div className='contact'>
                            <a className="linkedin" title="Linkedin" href="https://www.linkedin.com/in/younous-el-idrissi-470168211/"><FiLinkedin size={25}/></a>
                            <a className="gmail" title="Send email" href="mailto:younes.idrissi.07@gmail.com"><SiGmail size={25} /></a>
                            <a className="github" title="Github" href="https://github.com/younousIdrissi"><FiGithub size={25} /></a>
                        </div>
                        
                        <div className='my-home-btns'>
                            <Link to="about" className="name noselect" spy={true} smooth={true}><button className="name noselect">About me</button></Link>
                            <form method="get" action={resume}>
                                <button type="submit"><span>My resume</span></button>
                            </form>

                            <Link to="projects" className="name noselect" spy={true} smooth={true}><button className="name noselect">My projects</button></Link>
                        </div>
                    </div>
                    
                </div>
                
            </div>

        </div>
    )
}

export default Home;